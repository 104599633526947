import React from 'react';

import { getCancellationBadge } from './HolidayCollection';
import { Badge, Pricing as OfferPricing } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Prices } from '@Core/prices/Prices';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { DepositSash } from '@Pages/search-results/card/DepositSash';
import { DiscountSash } from '@Pages/search-results/card/DiscountSash';
import { SearchResultSash } from '@Pages/search-results/card/SearchResultSash';

type PricingData = Pick<
  OfferPricing,
  | 'showDiscount'
  | 'discountPresentation'
  | 'pricingPresentation'
  | 'discountPercentage'
  | 'displayedPricingItems'
  | 'totalPaidAtHotel'
>;

export interface PricingProps extends ClassNameProps {
  lowestDeposit?: number;
  lowestDepositPerPerson?: number;
  pricing: PricingData;
  badges: Badge[];
  localCurrency?: string;
}

export const Pricing: React.FC<PricingProps> = ({
  badges,
  className,
  lowestDeposit,
  lowestDepositPerPerson,
  pricing,
  localCurrency,
}) => {
  const cancellationBadge = getCancellationBadge(badges);
  const { priceQuoteUnit } = usePriceQuoteUnit();

  const lowestDepositPrice =
    lowestDepositPerPerson && lowestDeposit && priceQuoteUnit === 'PER_PERSON'
      ? lowestDepositPerPerson
      : lowestDeposit;

  return (
    <div className={className}>
      <Prices
        pricing={pricing}
        localCurrency={localCurrency}
        strikeThroughStyles={{ marginBottom: '4xs' }}
      />

      {!!cancellationBadge && (
        <SearchResultSash
          {...cancellationBadge}
          sx={{ marginBottom: '3xs' }}
        />
      )}

      {pricing.discountPresentation && (
        <div sx={{ marginBottom: '3xs' }}>
          <DiscountSash pricing={pricing} />
        </div>
      )}

      {!!lowestDepositPrice && (
        <div>
          <DepositSash lowestDeposit={lowestDepositPrice} />
        </div>
      )}
    </div>
  );
};
